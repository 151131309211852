<script setup lang="ts">
const props = defineProps<{
  title?: string
  message?: string
}>()
</script>

<template>
  <div
    class="pointer-events-auto flex rounded-md border border-muted-200 bg-white shadow-md dark:border-muted-800 dark:bg-muted-900"
  >
    <div
      class="flex w-16 items-center justify-center rounded-l-lg bg-muted-50 dark:bg-muted-700"
    >
      <svg
        class="size-6 text-muted-400 dark:text-muted-50"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        role="img"
        width="32"
        height="32"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 32 32"
      >
        <path
          fill="currentColor"
          d="M17 22v-8h-4v2h2v6h-3v2h8v-2h-3zM16 8a1.5 1.5 0 1 0 1.5 1.5A1.5 1.5 0 0 0 16 8z"
        />
        <path
          fill="currentColor"
          d="M16 30a14 14 0 1 1 14-14a14 14 0 0 1-14 14Zm0-26a12 12 0 1 0 12 12A12 12 0 0 0 16 4Z"
        />
      </svg>
    </div>
    <div class="text-md min-w-[220px] py-4 pl-8 pr-12">
      <slot>
        <strong v-if="props.title" class="text-semibold font-heading">
          {{ props.title }}
        </strong>
        <p
          v-if="props.message"
          class="whitespace-pre text-muted-600 dark:text-muted-400"
        >
          {{ props.message }}
        </p>
      </slot>
    </div>
  </div>
</template>
